<template>
  <div id="app">
    <appHeader :navActive.sync="navActive" />
    <transition :css="false" @enter="navEnter" @leave="navLeave" mode="out-in">
      <appNavigation v-if="navActive" :navActive.sync="navActive" />
    </transition>
    <section id="container">
      <div id="scroll-wrap" ref="scrollWrap">
        <div id="scroll-inner">
          <transition :css="false" @enter="pageEnter" @leave="pageLeave" mode="out-in">
            <router-view />
          </transition>
          <app-banner ref="banner" />
        </div>
        <appFooter :smoothScrollBar.sync="smoothScrollBar" />
      </div>
    </section>
    <div id="scroll-fake" :style="{height: `${this.contHeight}px`, width: '1264px'}"></div>
    <div class="loading" v-if="loading" @wheel.prevent>
      <div class="loading-spinner" v-if="ie11">
        <div class="circle">
          <div></div>
        </div>
      </div>
      <img v-else :src="require('@/assets/images/loading.svg')" alt="" />
    </div>
    <portal-target name="sticky-component" multiple tag="div" class="sticky-wrap" />
  </div>
</template>
<script>
  // import style
  import {layouts} from "@/utils/global-methods";
  import {mapState, mapActions} from "vuex";
  import {TweenMax, Power0, Power4} from "gsap";
  import Scrollbar from "smooth-scrollbar";
  import ResizeObserver from "resize-observer-polyfill";
  import bus from "@/utils/bus";
  export default {
    name: "appDefault",
    data() {
      return {
        loading: false,
        pageChange: false,
        navActive: false,
        winScroll: [0, 0],
        smoothScrollBar: null,
        windowScrollTimer: null,
        ro: new ResizeObserver(entries => {
          //console.log('ResizeObserver');
          for (const entry of entries) {
            const {height} = entry.contentRect;
            this.AC_CONT_HEIGHT(height);
            if (this.pageChange) {
              setTimeout(() => {
                window.scrollTo(this.savedPosition.x, this.savedPosition.y);
              }, 1);

              this.pageChange = false;
            }
          }
        })
      };
    },
    computed: {
      ...mapState(["contHeight", "savedPosition"]),

      ie11() {
        return !!window.MSInputMethodContext && !!document.documentMode;
      }
    },
    watch: {
      "$route.path"() {
        this.pageChange = true;
        this.navActive = false;
      }
    },
    mounted() {
      this.ro.observe(document.querySelector("#scroll-wrap"));
      bus.$on("winScrollTo", (x, y, duration) => {
        this.smoothScrollBar.scrollTo(x, y, duration || 1000, {
          //callback: () => console.log('done!'),
          ease: Power0.easeInOut
        });
      });
      bus.$on("loading", status => {
        this.loading = status;
      });
      this.smoothScrollFn();
    },
    components: {
      appHeader: layouts("app-header"),
      appNavigation: layouts("app-navigation"),
      appBanner: layouts("app-banner"),
      appFooter: layouts("app-footer")
    },
    methods: {
      ...mapActions(["AC_CONT_HEIGHT"]),
      windowScrollEvt() {
        //console.log('windowScroll');
        if (this.navActive) {
          this.winScroll[0] = window.pageXOffset;
        }
        this.smoothScrollBar.scrollTop = window.pageYOffset;
        this.smoothScrollBar.scrollLeft = window.pageXOffset;
        if (document.querySelector(".tab-top")) {
          document.querySelector(
            ".tab-top"
          ).style.transform = `translateX(${-window.pageXOffset}px)`;
        }
        if(document.querySelector("header")) {
          document.querySelector("header").style.transform = `translateX(${-window.pageXOffset}px)`;
        }
      },
      smoothScrollFn() {
        this.smoothScrollBar?.destroy();
        const scrollArea = document.querySelector(this.navActive ? "nav" : "#container");
        this.smoothScrollBar = Scrollbar.init(scrollArea, {});
        this.smoothScrollBar.addListener(val => {
          window.scrollTo(val.offset.x, val.offset.y);
        });
        window.removeEventListener("scroll", this.windowScrollEvt);
        window.addEventListener("scroll", this.windowScrollEvt);
      },
      navEnter(el, done) {
        this.winScroll = [window.pageXOffset, window.pageYOffset];
        el.scrollTo(window.pageXOffset, 0);

        // navigation bg
        const bg = el.querySelector(".bg");
        TweenMax.to(bg, 0.8, {
          y: "100%",
          ease: Power4.easeInOut,
          onComplete: () => {
            if (!this.navActive) return;
            //this.scrollFake();
            this.ro.unobserve(document.querySelector("#scroll-wrap"));
            this.ro.observe(document.querySelector("nav .gnb-wrap"));
            this.smoothScrollFn();
            el.scrollTo(0, 0);
            window.scrollTo(this.winScroll[0], 0);
          }
        });

        // navigation txt
        const anime = el.querySelectorAll(".anime1");
        const anime2 = el.querySelectorAll(".anime2");
        const anime3 = el.querySelectorAll(".logo");
        TweenMax.to(anime, 0.8, {
          y: "0%",
          delay: 0.3,
          ease: Power4.easeInOut
        });
        TweenMax.to(anime2, 0.8, {
          y: "0%",
          delay: 0.3,
          ease: Power4.easeInOut
        });
        TweenMax.to(anime3, 0.8, {
          opacity: "1",
          delay: 0.3,
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });
      },
      navLeave(el, done) {
        //this.scrollFake();
        const scrollWrap = document.querySelector("#scroll-wrap");
        const scrollFake = this.$el.querySelector("#scroll-fake");
        scrollFake.style.height = `${scrollWrap.clientHeight}px`;
        this.ro.unobserve(document.querySelector("nav .gnb-wrap"));
        this.ro.observe(document.querySelector("#scroll-wrap"));
        this.smoothScrollFn();
        window.scrollTo(this.winScroll[0], this.winScroll[1]);

        // navigation bg
        const bg = el.querySelector(".bg");
        TweenMax.to(bg, 0.8, {
          y: "0%",
          delay: 0.2,
          ease: Power4.easeInOut
        });

        // navigation txt
        const anime = el.querySelectorAll(".anime1");
        const anime2 = el.querySelectorAll(".anime2");
        const anime3 = el.querySelectorAll(".logo");

        TweenMax.to(anime, 0.8, {
          y: "110%",
          ease: Power4.easeInOut
        });
        TweenMax.to(anime2, 0.8, {
          y: "-110%",
          ease: Power4.easeInOut
        });
        TweenMax.to(anime3, 0.8, {
          opacity: "0",
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });
      },
      pageEnter(el, done) {
        const fadeEl = [
          el,
          //document.querySelector(".banner-area"),
          document.querySelector("footer")
        ];
        TweenMax.fromTo(
          fadeEl,
          this.navActive ? 0 : 0.5,
          {opacity: 0},
          {
            opacity: 1,
            onComplete: () => {
              this.navActive = false;
              TweenMax.set(el, {clearProps: "all"});
              TweenMax.set(document.querySelector(".banner-area"), {clearProps: "all"});
              this.smoothScrollFn();
              done();
            }
          }
        );
      },
      pageLeave(el, done) {
        //console.log(this.navActive);
        const fadeEl = [
          el,
          document.querySelector(".banner-area"),
          document.querySelector("footer")
        ];
        TweenMax.fromTo(
          fadeEl,
          this.navActive ? 0 : 0.5,
          {opacity: 1},
          {
            opacity: 0,
            onComplete: () => {
              done();
            }
          }
        );
      }
    }
  };
</script>
